import "core-js/modules/es.array.push.js";
import { mixins } from "@/plugins/mixins";
import { ElLoading, ElMessage } from "element-plus";
import 'element-plus/es/components/loading/style/css'
import 'element-plus/es/components/message/style/css'

import bus from "@/plugins/bus";
export default {
  name: "faultInfoAdd",
  mixins: [mixins],
  data() {
    return {
      edit: false,
      carTypeList: [],
      formModel: {
        id: null,
        name: '',
        imgPath: '',
        imgs: [],
        items: [],
        // 真实名称
        initItem: [] //页面显示
      },

      formRules: {
        name: [{
          required: true,
          message: '请输入故障类型名称',
          trigger: 'blur'
        }],
        deposit: [{
          required: true,
          message: '请输入押金金额',
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    beforeRemove() {
      this.formModel.imgPath = null;
    },
    handlePictureCardPreview(uploadFile) {
      this.dialogImageUrl = uploadFile.url;
      this.dialogVisible = true;
    },
    changeFile(file, fileList) {
      this.formModel.imgs = fileList;
      if (file.raw.type === 'image/jpeg' || file.raw.type === 'image/jpg' || file.raw.type === 'image/png') {
        if (file.size / 1024 > 500) {
          ElMessage.error('图片大小不能超过500KB。');
          const currIdx = this.formModel.imgs.indexOf(file);
          this.formModel.imgs.splice(currIdx, 1);
          return;
        } else {
          this.getBase64(file.raw);
        }
      } else {
        ElMessage.error('图片格式只能为【jpg、jpeg、png】');
        const currIdx = this.formModel.imgs.indexOf(file);
        this.formModel.imgs.splice(currIdx, 1);
        return;
      }
    },
    getBase64(file) {
      //把图片转成base64编码
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.formModel.imgPath = reader.result;
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    },
    addItem() {
      this.formModel.initItem.push({
        seq: this.formModel.initItem.length + 1,
        itemName: '',
        editable: true
      });
    },
    deleteItem(delItem) {
      this.formModel.initItem.forEach(function (item, index, arr) {
        if (item.seq === delItem.seq) {
          arr.splice(index, 1);
        }
      });
      // 重新排序
      this.formModel.initItem.forEach((item, index) => {
        item.seq = index + 1;
      });
    },
    submitForm() {
      this.$refs.formRef.validate(valid => {
        if (valid) {
          const loading = ElLoading.service();
          const callBack = res => {
            loading.close();
            if (res.code === 200) {
              ElMessage({
                message: res.message,
                type: 'success'
              });
              this.$router.back();
              if (this.$route.meta && this.$route.meta.parent) {
                bus.emit(this.$route.meta.parent, true);
              }
            } else {
              ElMessage.error(res.message);
            }
          };
          if (this.formModel.initItem && this.formModel.initItem.length > 0) {
            this.formModel.initItem.forEach(item => {
              this.formModel.items.push(item.itemName);
            });
            // } else {
            //     ElMessage.error('标签不能为空。')
          }

          if (this.edit) {
            this.$api.business.faultInfo.update(this.formModel).then(callBack);
          } else {
            this.$api.business.faultInfo.create(this.formModel).then(callBack);
          }
        }
      });
    }
  },
  beforeMount() {
    if (this.$route.query.id) {
      this.edit = true;
      const loading = ElLoading.service();
      this.$api.business.faultInfo.detail({
        id: this.$route.query.id
      }).then(res => {
        if (res.code === 200) {
          this.formModel.id = res.data.id;
          this.formModel.name = res.data.name;
          if (res.data.imgPath) {
            this.formModel.imgPath = res.data.imgPath;
            this.formModel.imgs = [{
              name: 'food.jpg',
              url: res.data.imgPath
            }];
          }
          this.formModel.items = [];
          this.formModel.initItem = [];
          if (res.data.items && res.data.items.length > 0) {
            let x = 0;
            res.data.items.forEach(item => {
              x = x + 1;
              this.formModel.initItem.push({
                seq: x,
                itemName: item.itemName,
                editable: true
              });
            });
          }
        } else if (res.code === 121601) {
          this.$router.replace({
            name: '404'
          });
        } else {
          ElMessage.error(res.message);
          this.$router.back();
        }
      }).finally(() => {
        loading.close();
      });
    }
  }
};